export const GOOGLE_ANALYTICS_ID = "UA-153291514-1";

export const GOOGLE_NETWORK_CODE = "21916527510";

export const GOOGLE_OPTIMIZE_ID = "GTM-TM4SN5K";
/*
const square = [250, 250];
const smallSquare = [200, 200];
const banner = [468, 60];
const leaderboard = [728, 90];
const inlineRectangle = [300, 250];
const largeRectangle = [336, 280];
const skyscraper = [120, 600];
const wideSkyscraper = [160, 600];
const halfPage = [300, 600];
const largeLeaderboard = [];

const bannerSize = [728, 90];
const gamesnortSize = [870, 280];
const ignSize = [970, 250];

const sidebarSizes = [[300, 100], [300, 250], [320, 100], [336, 280], ["Fluid"]];

const adsenseSizes = [
  // recommended
  [300, 250],
  [336, 280],
  [728, 90],
  [300, 600],
  // other horizontal
  [970, 90],
  // other vertical
  [120, 600],
  [160, 600],
  // other square
  [250, 250],
  [200, 200]
];
*/

const availableCodes = [
  "gameqik-article-top-banner",
  "gameqik-article-sidebar",
  "gameqik-review-top-banner",
  "gameqik-review-sidebar",
];

export function initDFP() {
  console.log("dfp: init");
  window.googletag = window.googletag || { cmd: [] };
  googletag.cmd.push(() => {
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });
}

export function setDFP(ads) {
  googletag.cmd.push(() => {
    console.log("dfp: destroying old slots");
    googletag.destroySlots();
    const slots = ads.map((ad) => {
      if (!availableCodes.includes(ad.code)) {
        throw new Error(`Unknown ad code ${ad.code}`);
      }
      console.log("dfp:", ad);
      const mapping = ad.sizes
        .reduce((mapping, size) => mapping.addSize(size[0], size[1]), googletag.sizeMapping())
        .build();
      console.log("dfp: mapping is", mapping);
      return googletag
        .defineSlot(`/${GOOGLE_NETWORK_CODE}/${ad.code}`, ad.size, ad.id)
        .defineSizeMapping(mapping)
        .updateTargetingFromMap(getClickTargetingMap())
        .addService(googletag.pubads());
    });
    console.log("dfp: slots", slots);
    slots.forEach((slot) => googletag.display(slot));
    console.log("dfp: displayed");
  });
}

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

export function pageview(path) {
  gtag("config", GOOGLE_ANALYTICS_ID, {
    page_path: path,
  });
}

function getClickTargetingMap() {
  const url = new URL(window.location.href);
  const gclid = url.searchParams.get("gclid");
  const msclkid = url.searchParams.get("msclkid");

  if (gclid && msclkid) {
    // both !? we return nothing
    return {};
  } else if (gclid) {
    return {
      "click-id": `g:${gclid}`,
    };
  } else if (msclkid) {
    return {
      "click-id": `m:${msclkid}`,
    };
  } else {
    // neither, default, return nothing
    return {};
  }
}

export function initAnalytics(analytics) {
  // analytics includes things like { experiments, content_group1, etc }
  gtag("js", new Date());
  gtag("config", GOOGLE_ANALYTICS_ID, {
    ...analytics,
    content_group3: "trafficcop", // Ads Provider
    optimize_id: GOOGLE_OPTIMIZE_ID,
    custom_map: {
      dimension1: "gclid",
      dimension2: "msclkid",
    },
  });
  const url = new URL(window.location.href);
  for (let key of ["gclid", "msclkid"]) {
    const clkid = url.searchParams.get(key);
    if (!clkid) {
      continue;
    }
    gtag("event", `${key}_dimension`, {
      [key]: clkid,
      non_interaction: true,
    });
  }
}
