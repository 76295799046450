import * as Sentry from "@sentry/node";
import { Integrations as ApmIntegrations } from "@sentry/apm";
import { runExperiments, mergeExperimentData } from "../lib/experiments";

if (process.env.NODE_ENV === "production") {
  const isServer = typeof window === "undefined";
  console.log("Initializing sentry client");
  Sentry.init({
    integrations: [
      new ApmIntegrations.Tracing({ tracingOrigins: ["api.gamesnort.com", "localhost", /^\//] }),
    ],
    tracesSampleRate: isServer ? 0.25 : 0.05,
    sampleRate: isServer ? 1.0 : 0.1,
    environment: "gameqik-com-" + (isServer ? "server" : "browser"),
    dsn: "https://3da92a0dd8304f18906ee7a35c4a9e8a@o31068.ingest.sentry.io/5251829",
    ignoreErrors: [
      `UET is not defined`,
      `'geo' is not defined`,
      `ConsentString - A vendor list is required to encode a consent string`,
      `Identifier 'VIDEO_PLAYING' has already been declared`,
      `window.getPlayer is not a function`,
      `The popup has been closed by the user before finalizing the operation.`,
      `This operation has been cancelled due to another conflicting popup being opened.`,
    ],
    blacklistUrls: [/show_ads_impl/, /chrome-extension/],
  });
}

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import React from "react";
import { Provider } from "react-redux";
import App from "next/app";
import cookies from "next-cookies";

import { makeStore } from "../redux/store";
import { themes } from "../theme";
import { Context } from "../context";

import { initAnalytics } from "../analytics";
import { getMenu } from "../api";

// https://github.com/zeit/next.js/tree/canary/examples/with-sentry-simple

const isServer = typeof window === "undefined";

function initLazyLoad() {
  var lazyItems = [].slice.call(document.querySelectorAll("img"));
  var lazyVideos = [].slice.call(document.querySelectorAll(".embed-container iframe"));
  var lazyItems = [].concat(lazyItems, lazyVideos).filter(function (item) {
    return item.dataset.src;
  });
  if (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of isIntersecting
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!("isIntersecting" in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype, "isIntersecting", {
        get: function () {
          return this.intersectionRatio > 0;
        },
      });
    }
    if (lazyItems.length) {
      let lazyItemObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let lazyItem = entry.target;
            lazyItem.src = lazyItem.dataset.src;
            lazyItemObserver.unobserve(lazyItem);
          }
        });
      });
      lazyItems.forEach(function (lazyItem) {
        lazyItemObserver.observe(lazyItem);
      });
    }
  } else {
    function loadItems(e) {
      setTimeout(function () {
        if (lazyItems.length) {
          lazyItems.forEach(function (lazyItem) {
            lazyItem.setAttribute("src", lazyItem.getAttribute("data-src"));
          });
        }
      }, 500);
      window.removeEventListener("scroll", loadItems);
    }
    window.addEventListener("scroll", loadItems);
  }
}

class Application extends React.Component {
  /*
  Server side only initialization
  */
  static async getInitialProps(appContext) {
    /* We never use <Link /> so getInitialProps should always be server right? */
    if (!isServer) {
      const error = new Error("Expected to be on the server");
      setTimeout(() => {
        Sentry.captureException(error);
      }, 1000);
      console.error(error);
    }

    /* Make the store */
    const store = makeStore();

    /* By default cache unless no-cache is specified */
    const cached = appContext.ctx.req.headers["cache-control"] !== "no-cache";

    /* Calculate if isMobile */
    const UA = /Mobi/;

    /* Provide some context */
    appContext.ctx.store = store;
    appContext.ctx.cookies = appContext.ctx.cached = cached;
    appContext.ctx;
    const ua = appContext.ctx.req.headers["user-agent"] || "";
    appContext.ctx.mobile = /Mobi/.test(ua);
    const appProps = await App.getInitialProps(appContext);

    /* Get data that is common to all pages */
    const theme = appContext.ctx.query.theme || cookies(appContext.ctx).gameqik_theme || "light";
    const { menu } = await getMenu({ cached });
    const { query, asPath } = appContext.ctx;
    const path = asPath.split("?")[0];
    const experimentParams = { query, path, cookies: cookies(appContext.ctx) };
    const experiments = runExperiments(experimentParams);

    return {
      ...appProps,
      initialStoreState: store.getState(),
      theme,
      menu,
      experiments,
      mobile: appContext.ctx.mobile,
    };
  }

  /*
  Client side only initialization (by convention)
  */
  componentDidMount() {
    if (isServer) {
      return;
    }

    /* Basic analytics - data are provided by a page's getInitialProps */
    const analytics = {
      ...(this.props.pageProps.analytics || {}),
      experiments: this.props.experiments,
    };

    initAnalytics(analytics);
    /* Including any experiments, which we have to record */
    analytics.experiments.forEach((experiment) => {
      document.cookie = `${experiment.id}=${experiment.variant}; path=/`;
    });

    /* We also record the theme */
    document.cookie = `gameqik_theme=${this.props.theme}; path=/`;

    initLazyLoad();
  }

  /*
  Server side and client side, only uses props
  */
  render() {
    const store = makeStore(this.props.initialStoreState);
    const theme = themes[this.props.theme];
    const { menu, mobile, experiments } = this.props;
    const experimentData = mergeExperimentData(experiments);
    const context = { theme, menu, mobile, experimentData };
    return (
      <Provider store={store}>
        <Context.Provider value={context}>
          <this.props.Component {...this.props.pageProps} menu={menu} />
        </Context.Provider>
      </Provider>
    );
  }
}

export default Application;
